"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, find, hasClass} from '@elements/dom-utils';

const selectors = {
    base: '.js-shadow-nav'
};

const isMobile = () => matchMedia('(max-width: 767px)').matches;

let lastScrollPosition = 0,
    isRunning = false,
    topOffset = 0,
    nav;

export function init () {
    if (!isMobile) {
        return;
    }


    if (isMobile()) {
        onFind(".main-navbar", function (element) {
            nav = element;
            setupShadow();
        });
    }
    else {
        onFind(selectors.base, function (element) {
            nav = element;
            setupShadow();
        });
    }


    function setupShadow() {
        if(!isRunning) {
            isRunning = true;
            requestAnimationFrame(interval);
        }
    }

    function interval() {
        if (lastScrollPosition === window.scrollY) {
            requestAnimationFrame(interval);
            return;
        }

        updateDom();
        lastScrollPosition = window.scrollY;
        requestAnimationFrame(interval);
    }


    function updateDom() {
        let affixOffset = nav.dataset.affixOffset;

        nav.isAffix = affixOffset > window.scrollY;

        let setDefault = lastScrollPosition < topOffset;
        topOffset = nav.offsetHeight;

        if(isMobile()) {
            if (nav.isAffix && !setDefault) {
                addClass("main-navbar--show-meta-nav-mobile", find(".main-navbar"));
                if(hasClass("main-navbar--fixed", nav)) {
                    addClass("main-navbar--shadow", nav);
                }
            } else {
                if(!setDefault) {
                    removeClass("main-navbar--show-meta-nav-mobile", find(".main-navbar"));
                    if(hasClass("main-navbar--fixed", nav) && hasClass("main-navbar--shadow", nav)) {
                        removeClass("main-navbar--shadow", nav);
                    }
                }
            }
        } else {
            if (nav && nav.isAffix && !setDefault) {
                setShadow(nav);
            } else {
                if(!setDefault) {
                    unsetShadow(nav);
                }
            }
        }
    }

}


export function setShadow(nav) {
    addClass('main-navbar--shadow', nav);
 }

export function unsetShadow(nav) {
    removeClass('main-navbar--shadow', nav);
}
