"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {find, findIn, addClass, findAllIn, on, toggleClass, findAll} from '@elements/dom-utils';
import asyncAppend from "@elements/async-append";
import {onEnterViewPort} from "@elements/viewport-utils";


const selectors = {
    base: '.js-load-in-viewport',
    loading: '.js-load-in-viewport__loading',
    result: '.js-load-in-viewport__result',
    notifications: '.js-load-in-viewport__notifications'
};


export function init () {
    onFind(selectors.base, function (baseElement) {

        let url = baseElement.dataset.loadInViewportUrl;

        onEnterViewPort(baseElement, function (baseElement) {
            asyncAppend({
                target: findIn(selectors.result, baseElement),
                loading: findIn(selectors.loading, baseElement),
                notifications: findIn(selectors.notifications, baseElement),
            }, fetch(url));
        }, { offset: 300 });
    });
}