import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-ajax-form', function ($element) {
        let ajaxScrollTopButtons = findAllIn('.js-ajax-scroll-top', $element);
        if (ajaxScrollTopButtons) {

            on('fetch.ajax-form', function () {
                scrollTop($element);
            }, $element);

            ajaxScrollTopButtons.map(function (ajaxScrollTopBtn) {
                on('click', function () {
                    scrollTop($element);
                }, ajaxScrollTopBtn);
            });
        }
    });
}

export function scrollTop($element) {
    let navOffset = matchMedia('(max-width: 767px)').matches ? 120 : 120;
    let offset = $element.getBoundingClientRect().top + window.pageYOffset - navOffset;

    window.scroll({
        top: offset,
        behavior: 'smooth'
    });
}