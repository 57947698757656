import './scripts/common';
import './scripts/common-nav';
import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


import * as datepicker from "@elements/datepicker";
datepicker.init();

import * as datepickerRange from '@elements/datepicker-range';
datepickerRange.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init({submitOnReset:true, addUrlParams: true});

import * as scrollToTop from './scripts/scroll-to-top';
scrollToTop.init();

import {createApp} from 'vue';
import BookingQuickfinder from './scripts/components/VueBookingQuickfinder.vue';
const vueApp = createApp({
    components: {
        BookingQuickfinder
    }
})
vueApp.mount('#vue-booking-quickfinder');

import * as conditionalForm from './scripts/conditional-form';
conditionalForm.init();

import * as historyBack from './scripts/history-back';
historyBack.init();

import * as googleMap from './scripts/google-map';
googleMap.init();

import * as renderTemplate from './scripts/render-template';
renderTemplate.init();

import * as loadInViewport from './scripts/load-in-viewport';
loadInViewport.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as anchorNav from '@elements/anchor-nav/bootstrap-5';
anchorNav.init(
    {
        renderItem: function (text, anchor) {
            return '<li class="nav-item anchor-nav__item js-anchor-nav__item"><a class="nav-link js-smoothscroll__link js-anchor-nav__link anchor-nav__link" href="#' + anchor + '"><span class="anchor-nav__item-icon icon icon-chevron"></span>' + text + '</a></li>';
        },
        smoothScroll: false,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: (matchMedia('(min-width: 768px)').matches ? 181 : 51)
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);