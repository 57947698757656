"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, is,setAttribute, removeAttribute} from "@elements/dom-utils";
import {serializeArray} from "./helpers/dom-utils-extended";

export function init() {
    onFind('.js-conditional-form', function (form) {
        let formParts = findAllIn('.js-conditional-form__part', form);

        let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',form);
        optionalRequiredFields.map((element) => {
            element.required = false;
        });

        on('change', function(event) {
            formParts.map(setAttribute('hidden', 'hidden'));

            let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',form);
            optionalRequiredFields.map((element) => {
                element.required = false;
            });

            let formData = serializeArray(form);

            formData
                .filter(({name, value}) => !!value)
                .forEach(({name, value}) => {
                    let currentParts = formParts.filter(is(`[data-name="${name}"]`));
                    currentParts.filter(is(`[data-value="${value}"]`)).map(function(currentPart) {
                        removeAttribute('hidden', currentPart);

                        let optionalRequiredFields = findAllIn('.js-conditional-form__optional-required',currentPart);
                        optionalRequiredFields.required = true;

                        optionalRequiredFields.map((element) => {
                            element.required = true;
                        });
                    });
                });

            formValidation.init();

        }, form);
    });
}
